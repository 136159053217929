import { render, staticRenderFns } from "./DocumentDowload.vue?vue&type=template&id=547b6972&scoped=true&"
import script from "./DocumentDowload.vue?vue&type=script&lang=js&"
export * from "./DocumentDowload.vue?vue&type=script&lang=js&"
import style0 from "./DocumentDowload.vue?vue&type=style&index=0&id=547b6972&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "547b6972",
  null
  
)

export default component.exports