<template>
  <div class="vertical-center justify-content-center">
    <b-container class="pb-5 app-custom-width">
        <b-row class="justify-content-center">
            <b-col lg="5" md="7">
                <b-overlay :show="loading" :opacity="0.6"> 
                <b-card no-body class="bg-white border-0 mb-0">
                    <b-card-body class="px-lg-4 py-lg-4">
                         <div class="mb-2">
                            {{trans('download info', 300)}}  <b-link @click="download">{{trans('click_hier', 300)}}</b-link>
                        </div>
                    </b-card-body>
                </b-card>
                </b-overlay>  
            </b-col>
        </b-row>
    </b-container>
  </div>
</template>


<script>
import axios from "@axios";

  export default {

    mounted() {
        this.download();
    },

    data() {
      return {
        token: this.$route.params.token,
        step: 1,
        bad_token: false,
        loading: true
      };
    },
    methods: {
     download() {
       
              axios.post("downloadDocument", {
                    token: this.token
               }, {responseType: 'blob'})
              .then( (res) => {

                if(res.status == 200){
                    const url = window.URL.createObjectURL(new Blob([res.data], {type : 'application/pdf'}));
                    const link = document.createElement('a');
                    link.href = url;
                    var filename = '';
                    var disposition = res.headers['content-disposition'];
                    if (disposition && disposition.indexOf('attachment') !== -1) {
                        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        var matches = filenameRegex.exec(disposition);
                        if (matches != null && matches[1]) { 
                            filename = matches[1].replace(/['"]/g, '');
                        }
                    }
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                }      
              })
              .catch((error) => {
                      console.error(`error during request: ${error}`);
                  
              }).finally(() => {
                        this.loading = false;
                    }); 
        }

    }
  };
</script>

<style scoped>
.vertical-center {
  min-height: calc(100% -64px); 
  min-height: calc(100vh - 64px); 


  display: flex;
  align-items: center;
}

.app-custom-width{
  min-width: 1200px !important;
  max-width: 1200px !important;
}

.app-local-image-logo{
  margin-left: -20px;
}
</style>
